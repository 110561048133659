import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const RecommendedWrapper = styled.section`
  background: var(--black_background);
  display: flex;
  margin-top: 2rem;
`

export const RecommendedVoid = styled.span`
  align-items: center;
  background: var(--black_background);
  color: var(--white);
  display: flex;
  padding: 3rem;
  text-decoration: none;
  width: 50%;
`

export const RecommendedLink = styled(AniLink)`
  align-items: center;
  background: var(--black_background);
  color: var(--white);
  display: flex;
  padding: 3rem;
  text-decoration: none;
  transition: background 0.5s;
  width: 50%;

  &:hover {
    background: var(--black_background_contrast);
  }

  &.previous {
    border-right: 3px solid var(--black_background_contrast);
  }

  &.next {
    justify-content: flex-end;
  }

  &.previous:before {
    content: "\\2190";
    margin-right: 0.5rem;
  }

  &.next:after {
    content: "\\2192";
    margin-left: 0.5rem;
  }

  ${media.lessThan("large")`
    border-top: 3px solid var(--black_background_contrast);
    border-bottom: 3px solid var(--black_background_contrast);
    padding: .5rem;
  `}
`
