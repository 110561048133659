import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ShareButtons from "../components/ShareButtons"
import RecommendedPosts from "../components/RecommendedPosts"
import Comments from "../components/Comments"

import * as S from "../components/Post/styled"

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const next = pageContext.nextPost
  const previous = pageContext.previousPost
  const siteUrl = pageContext.siteUrl

  return (
    <Layout visibleSideBar={true}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />
      <S.PostHeader>
        <S.PostDate>
          {post.frontmatter.date} • {post.timeToRead} min de leitura
        </S.PostDate>
        <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
        <S.DestaqueImage
          src={`/${post.frontmatter.image}`}
          alt={post.frontmatter.alt}
        />
        <S.PostDescription>{post.frontmatter.description}</S.PostDescription>
      </S.PostHeader>
      <S.MainContent>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        <p>
          Escrito por:{" "}
          <AniLink
            to="/sobre"
            cover
            direction="right"
            bg="#08070e"
            duration={0.6}
          >
            {post.frontmatter.author}
          </AniLink>
        </p>
        <p>
          <strong>COMPARTILHE!</strong>
          <ShareButtons
            title={post.frontmatter.title}
            url={`${siteUrl}/${post.fields.slug}`}
            twitterHandle="_MsLinda"
          />
        </p>
      </S.MainContent>
      <RecommendedPosts next={next} previous={previous} />
      <Comments url={post.fields.slug} title={post.frontmatter.title} />
    </Layout>
  )
}

export const query = graphql`
  query Post($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        author
        image
        alt
      }
      html
      timeToRead
    }
  }
`

export default BlogPost
